<template>
  <div class="details">
    <div class="title">{{data.cname}}</div>
    <div class="line" v-if="data.cname">
      <div class="line-title">【 疾病名字 】</div>
      <div class="line-text" v-html="data.cname"></div>
    </div>
    <div class="line" v-if="data.ename">
      <div class="line-title">【 英文名字 】</div>
      <div class="line-text" v-html="data.ename"></div>
    </div>
    <div class="line" v-if="data.alias">
      <div class="line-title">【 别&nbsp&nbsp&nbsp&nbsp&nbsp名 】</div>
      <div class="line-text" v-html="data.alias"></div>
    </div>
    <div class="line" v-if="data.category_path">
      <div class="line-title">【 类&nbsp&nbsp&nbsp&nbsp&nbsp别 】</div>
      <div class="line-text" v-html="data.category_path"></div>
    </div>
    <div class="line" v-if="data.icd">
      <div class="line-title">【 ICD 号 】</div>
      <div class="line-text" v-html="data.icd"></div>
    </div>

    <div class="column" v-if="data.summarize">
      <div class="line-title">【 概&nbsp&nbsp&nbsp&nbsp&nbsp述 】</div>
      <div class="line-text" v-html="data.summarize"></div>
    </div>

    <div class="column" v-if="data.epidemiology">
      <div class="line-title">【 流行病学 】</div>
      <div class="line-text" v-html="data.epidemiology"></div>
    </div>
    <div class="column" v-if="data.pathogeny">
      <div class="line-title">【 病&nbsp&nbsp&nbsp&nbsp&nbsp因 】</div>
      <div class="line-text" v-html="data.pathogeny"></div>
    </div>
    <div class="column" v-if="data.pathogenesis">
      <div class="line-title">【 发病机制 】</div>
      <div class="line-text" v-html="data.pathogenesis"></div>
    </div>
    <div class="column" v-if="data.clinic">
      <div class="line-title">【 临床表现 】</div>
      <div class="line-text" v-html="data.clinic"></div>
    </div>
    <div class="column" v-if="data.syndrome">
      <div class="line-title">【 并&nbsp发&nbsp症 】</div>
      <div class="line-text" v-html="data.syndrome"></div>
    </div>
    <div class="column" v-if="data.lab">
      <div class="line-title">【 实验室检查 】</div>
      <div class="line-text" v-html="data.lab"></div>
    </div>
    <div class="column" v-if="data.assistant">
      <div class="line-title">【 其他辅助检查 】</div>
      <div class="line-text" v-html="data.assistant"></div>
    </div>
    <div class="column" v-if="data.diagnose">
      <div class="line-title">【 诊&nbsp&nbsp&nbsp&nbsp&nbsp断 】</div>
      <div class="line-text" v-html="data.diagnose"></div>
    </div>
    <div class="column" v-if="data.distinguish">
      <div class="line-title">【 鉴别诊断 】</div>
      <div class="line-text" v-html="data.distinguish"></div>
    </div>
    <div class="column" v-if="data.cure">
      <div class="line-title">【 治&nbsp&nbsp&nbsp&nbsp&nbsp疗 】</div>
      <div class="line-text" v-html="data.cure"></div>
    </div>
    <div class="column" v-if="data.prognosis">
      <div class="line-title">【 预&nbsp&nbsp&nbsp&nbsp&nbsp后 】</div>
      <div class="line-text" v-html="data.prognosis"></div>
    </div>
    <div class="column" v-if="data.prevent">
      <div class="line-title">【 预&nbsp&nbsp&nbsp&nbsp&nbsp防 】</div>
      <div class="line-text" v-html="data.prevent"></div>
    </div>
    <div class="line" v-if="data.download">
      <div class="line-title">【 操&nbsp&nbsp&nbsp&nbsp&nbsp作 】</div>
      <div class="line-text">
        <a href="" @click.prevent="onDownload(data, '1')">下 载</a>
      </div>
    </div>
    <div class="foot-details" v-if="data.medicine">
      <div class="line-title">【 相关药品 】</div>
      <div class="line-text">
        <ul v-html="data.medicine"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.examination">
      <div class="line-title">【 相关检查 】</div>
      <div class="line-text">
        <ul v-html="data.examination"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.confirmarion">
      <div class="line-title">【 相关循证 】</div>
      <div class="line-text">
        <ul v-html="data.confirmarion"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.confirmarion_details">
      <div class="line-title">【 相关循证详情 】</div>
      <div class="line-text">
        <ul v-html="data.confirmarion_details"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.research">
      <div class="line-title">【 相关研究进展 】</div>
      <div class="line-text">
        <ul v-html="data.research"></ul>
      </div>
    </div>
    <div class="foot-details" v-if="data.video">
      <div class="line-title">【 相关视频 】</div>
      <div class="line-text">
        <ul v-html="data.video"></ul>
      </div>
    </div>
  </div>
</template>

<script>
  import mixins from '../../mixins'
  export default {
    name: "diseaseDisease",
    mixins: [
      mixins
    ],
    data(){
      return {
        type: '1',
      }
    },
    created() {
      if (this.$route.query.id || this.$route.query.ID){
        this.getData({ id: this.$route.query.id || this.$route.query.ID, type: this.type })
      }
    },
    methods: {

    }
  }
</script>

